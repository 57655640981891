/* eslint-disable */
import medal1 from "./icons/medal.png";
import multitalent from "./icons/multitalent.png";
import second from "./icons/second.png";
import award from "./icons/award.png";
import third from "./icons/third.png";
import fischer from "./icons/fischer.png";
import bulletbill from "./icons/bulletbill.png";
import shark from "./icons/shark.png";
import pilopp from "./icons/top-right.png";
import ti from "./icons/10.png";
import tjue from "./icons/20.png";
import tretti from "./icons/30.png";
import femti from "./icons/50.png";
import hundre from "./icons/100.png";
import slingshot from "./icons/slingshot.png";
import boxer from "./icons/boxer.png";
import gm from "./icons/crown.png";
import im from "./icons/quality.png";
import fm from "./icons/radio.png";
import cm from "./icons/cm.png";
import giri from "./icons/giri.png";
import arm from "./icons/robot-arm.png";
import king from "./icons/king.png";
import gepard from "./icons/cheetah.png";
import bnils from "./icons/bnils.png";
import orangutan from "./icons/orangutan.png";
import winorangutan from "./icons/winorangutan.png";
import gg from "./icons/gg.png";
import wingg from "./icons/wingg.png";
import shock from "./icons/shock.png";
import langrokade from "./icons/langrokade.png";
import kortrokade from "./icons/kortrokade.png";
import rogue from "./icons/rogue.png";
import fox from "./icons/fox.png";
import soze from "./icons/soze.png";
import fight from "./icons/fight.png";
import promotion from "./icons/promotion.png";
import knightmate from "./icons/knightmate.png";
import bishopknight from "./icons/bishopknight.png";
import rock from "./icons/rock.png";
import point from "./icons/point.png";
import strat from "./icons/strat.png";
import hugh from "./icons/hugh.png";
import horse from "./icons/horse.png";
import gun from "./icons/gun.png";
import french from "./icons/french.png";
import mafia from "./icons/mafioso.png";
import sangria from "./icons/sangria.png";
import queen from "./icons/queen.png";
import anand from "./icons/anand.png";
import dominance from "./icons/dominance.png";
import london from "./icons/london.png";
import kg from "./icons/kg.png";
import composer from "./icons/composer.png";
import bongcloud from "./icons/bongcloud.png";
import god from "./icons/god.png";
import baby from "./icons/baby.png";
import gettingthere from "./icons/gettingthere.png";
import growing from "./icons/growing.png";
import talking from "./icons/chat.png";
import cashing from "./icons/cashing.png";
import porridge from "./icons/porridge.png";
import snore from "./icons/knot.png";
import glue from "./icons/glue.png";
import superglue from "./icons/sglue.png";
import troll from "./icons/troll.png";
import cross from "./icons/cross.png";
import keeper from "./icons/keeper.png";
import gandalf from "./icons/gandalf.png";
import pusheen from "./icons/pusheen.png";
import two from "./icons/two.png";
import three from "./icons/three.png";
import four from "./icons/four.png";
import five from "./icons/five.png";
import stroller from "./icons/stroller.png";
import bent from "./icons/bent.png";
import bishop from "./icons/bishop.png";
import halloween from "./icons/halloween.png";
import walk from "./icons/walk.png";
import walk_flipped from "./icons/walk_flipped.png";
import koth from "./icons/koth.png";
import qoth from "./icons/qoth.png";
import twins from "./icons/twins.png";
import sword from "./icons/sword.png";
import knight_rim from "./icons/knight_rim.png";
import speed from "./icons/speed.png";
import risk from "./icons/caution.png";
import danger from "./icons/skull.png";
import gaussian from "./icons/gaussian.png";
import berserk_win from "./icons/berserk_win.png";
import runner from "./icons/runner.png";
import spaghetti from "./icons/spaghetti.png";
import batman_begins from "./icons/batman_begins.png";
import tdk from "./icons/dark_knight.png";
import tdkr from "./icons/dark_knight_rises.png";

export default [
  {
    title: "STARTED",
    image: medal1,
    description: "Play one tournament",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.tournaments?.length;
    },
    xp: 10,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "GOLD",
    image: medal1,
    description: "Win a tournament",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.firstPlaces;
    },
    xp: 800,
    lvlReq: 7,
    permanent: true,
  },
  {
    title: "SILVER",
    image: second,
    description: "Finish second",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.secondPlaces;
    },
    xp: 500,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "BRONZE",
    image: third,
    description: "Finish third",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.thirdPlaces;
    },
    xp: 300,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "MASTER",
    image: award,
    description: "Finish among the top 3 in 5 tournaments",
    secret: false,
    fr_specific: false,
    check: function (player) {
      const pallPlasseringer =
        player.firstPlaces + player.secondPlaces + player.thirdPlaces;
      return pallPlasseringer > 4;
    },
    xp: 2000,
    lvlReq: 7,
    permanent: true,
  },
  {
    title: "UNDERDOG",
    image: slingshot,
    description: "Beat a higher rated player",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.beaten_higher_rated;
    },
    xp: 50,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "SHARK",
    image: shark,
    description: "Win a Blitz tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.filter(
        (t) => t.key === "blitz" && t.rank === 1
      ).length;
    },
    xp: 1000,
    lvlReq: 5,
    permanen: true,
  },
  {
    title: "BULLET BILL",
    image: bulletbill,
    description: "Win a Bullet tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find(
        (t) => t.key === "bullet" && t.rank === 1
      );
    },
    xp: 1000,
    lvlReq: 5,
    permanen: true,
  },
  {
    title: "PERFORMANCE",
    image: pilopp,
    description: "Perform higher than your rating in a tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.performance - t.rating > 0);
    },
    xp: 250,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "TEN",
    image: ti,
    description: "Get at least 10 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 9);
    },
    xp: 20,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "TWENTY",
    image: tjue,
    description: "Get at least 20 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 19);
    },
    xp: 20,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "THIRTY",
    image: tretti,
    description: "Get at least 30 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 29);
    },
    xp: 90,
    lvlReq: 2,
    permanent: true,
  },
  {
    title: "FIFTY",
    image: femti,
    description: "Get at least 50 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 49);
    },
    xp: 300,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "HUNDRED",
    image: hundre,
    description: "Get at least 100 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 99);
    },
    xp: 500,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "TITLEBREAKER",
    image: boxer,
    description: "Beat a titled player.",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_titled;
    },
    xp: 800,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "BERSERK",
    image: sword,
    description: "Berserk a game",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.berserk;
    },
    xp: 180,
    lvlReq: 5,
    category: "time",
    permanent: true,
  },
  {
    title: "BERSERK WIN",
    image: berserk_win,
    description: "Berserk a game and win",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.berserk_win;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: true,
  },
  {
    title: "USAIN BOLT",
    image: runner,
    description: "Checkmate in less than 10 seconds",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.fastest_checkmate < 11;
    },
    xp: 958,
    lvlReq: 5,
    category: "time",
    permanent: true,
  },
  {
    title: "KING",
    image: gm,
    description: "Beat a GM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_gm;
    },
    xp: 800,
    lvlReq: 7,
    permanent: true,
  },
  {
    title: "PRINCE",
    image: im,
    description: "Beat an IM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_im;
    },
    lvlReq: 7,
    xp: 600,
    permanent: true,
  },
  {
    title: "DAB",
    image: fm,
    description: "Beat an FM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_fm;
    },
    lvlReq: 5,
    xp: 350,
    permanent: true,
  },
  {
    title: "CM BEATER",
    image: cm,
    description: "Beat a CM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_cm;
    },
    lvlReq: 3,
    xp: 300,
    permanent: true,
  },
  {
    title: "WOW",
    image: arm,
    description: "Beat a player rated at least 300 points higher than you",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_300_higher_rated;
    },
    lvlReq: 5,
    xp: 300,
    permanent: true,
  },
  {
    title: "CHEETAH",
    image: gepard,
    description: "Play 40+ games in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.flest_partier_paa_en_turnering > 39;
    },
    lvlReq: 5,
    xp: 1000,
    permanent: true,
  },
  {
    title: "IMPOSSIBLE",
    image: king,
    description: "Beat Magnus (DrNykterstein)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_magnus;
    },
    lvlReq: 10,
    xp: 2001,
    permanent: true,
  },
  {
    title: "PLAYER",
    image: rogue,
    description: "Play 100 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.totalt_partier > 99;
    },
    lvlReq: 3,
    xp: 300,
    permanent: true,
  },
  {
    title: "HALLOWEEN",
    image: halloween,
    description: "Win with the Halloween Gambit",
    secret: false,
    enabled: true,
    fr_specific: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_halloween;
    },
    xp: 350,
    permanent: false,
  },
  {
    title: "FOX",
    image: fox,
    description: "Play 300 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.totalt_partier > 299;
    },
    lvlReq: 5,
    xp: 800,
    permanent: true,
  },
  {
    title: "KNIGHTMARE",
    image: knightmate,
    description: "Promote to knight with mate",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.wowowK;
    },
    lvlReq: 10,
    xp: 2000,
    permanent: true,
  },
  {
    title: "TEAMWORK",
    image: bishopknight,
    description: "Mate with bishop and knight",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.mate_with_bishop_and_knight;
    },
    lvlReq: 7,
    xp: 1200,
    permanent: true,
  },
  {
    title: "TO THE POINT",
    image: point,
    description: "Play 1. e4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_e4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "STRATEGIST",
    image: strat,
    description: "Play 1. d4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_d4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "HUGH GRANT",
    image: hugh,
    description: "Play 1. c4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_c4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "THE HORSE WHISPERER",
    image: horse,
    description: "Play 1. Nf3",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_nf3;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "SANGRIA",
    image: sangria,
    description: "Play the Spanish",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_spanish;
    },
    lvlReq: 2,
    xp: 50,
    permanent: true,
  },
  {
    title: "MOM'S SPAGHETTI",
    image: spaghetti,
    description: "Play the Italian",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_italian;
    },
    lvlReq: 2,
    xp: 50,
    permanent: true,
  },
  {
    title: 'THE "GAMBIT"',
    image: queen,
    description: "Play the Queen's Gambit",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_qg;
    },
    lvlReq: 2,
    xp: 50,
    permanent: true,
  },
  {
    title: "BABY STEPS",
    image: baby,
    description: "Win a game",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 0;
    },
    lvlReq: 1,
    xp: 30,
    permanent: true,
  },
  {
    title: "GROWING",
    image: growing,
    description: "Win 10 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 9;
    },
    lvlReq: 2,
    xp: 70,
    permanent: true,
  },
  {
    title: "GETTING THERE",
    image: gettingthere,
    description: "Win 30 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 29;
    },
    lvlReq: 3,
    xp: 300,
    permanent: true,
  },
  {
    title: "NOW WE'RE TALKING",
    image: talking,
    description: "Win 100 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 99;
    },
    lvlReq: 5,
    xp: 500,
    permanent: true,
  },
  {
    title: "CASHING IN",
    image: cashing,
    description: "Win 250 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 249;
    },
    lvlReq: 7,
    xp: 1000,
    permanent: true,
  },
  {
    title: "GOOD LUCK",
    image: cross,
    description: "Play Magnus (DrNykterstein)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_magnus;
    },
    lvlReq: 1,
    xp: 80,
    permanent: true,
  },
  {
    title: "GANDALF",
    image: gandalf,
    description: "Play Andrew Tang (penguingm1)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_penguin;
    },
    lvlReq: 1,
    xp: 50,
    permanent: true,
  },
  {
    title: "MEOW",
    image: pusheen,
    description: "Play PusheenMeow",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_pusheen;
    },
    lvlReq: 1,
    xp: 50,
    permanent: true,
  },
  {
    title: "KEEPER",
    image: keeper,
    description: "Keep at least 13 pieces (and win)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.min_own_pieces_taken < 4;
    },
    lvlReq: 3,
    xp: 250,
    permanent: true,
  },
  {
    title: "STREAK",
    image: two,
    description: "Win 2 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 1;
    },
    lvlReq: 2,
    xp: 60,
    permanent: true,
  },
  {
    title: "STREEAK",
    image: three,
    description: "Win 3 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 2;
    },
    lvlReq: 3,
    xp: 200,
    permanent: true,
  },
  {
    title: "STREEEAK",
    image: four,
    description: "Win 4 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 3;
    },
    lvlReq: 5,
    xp: 500,
    permanent: true,
  },
  {
    title: "STREEEEAK",
    image: five,
    description: "Win 5 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 4;
    },
    lvlReq: 7,
    xp: 800,
    permanent: true,
  },
  {
    title: "ADOPTION",
    image: stroller,
    description: "Win 10 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 9;
    },
    lvlReq: 10,
    xp: 1000,
    permanent: true,
  },
  {
    title: "TWINS",
    image: twins,
    description: "Win 20 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 19;
    },
    lvlReq: 10,
    xp: 2000,
    permanent: true,
  },
  {
    title: "HAARRIFIED",
    image: kg,
    description: "Play the King's Gambit",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_kg;
    },
    lvlReq: 2,
    xp: 50,
    permanent: false,
  },
  {
    title: "SPEED",
    image: speed,
    description:
      "Spend less than 2 seconds per move on average (in a won game lasting at least 20 moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_seconds_per_move < 2;
    },
    xp: 180,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "SPEED 2",
    image: speed,
    description:
      "Spend less than one second per move on average (in a won game lasting at least 20 moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_seconds_per_move < 1;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "NEED FOR SPEED",
    image: speed,
    description:
      "Spend less than half a second per move on average (in a won game lasting at least 20 moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_seconds_per_move < 0.5;
    },
    xp: 600,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "GOLDEN ORANGUTANG",
    image: winorangutan,
    description: "Win with 1. b4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_with_b4;
    },
    xp: 150,
    lvlReq: 3,
    permanent: false,
  },
  {
    title: "ANAND",
    image: anand,
    description: "Play the Nimzo-Indian",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_nimzo;
    },
    lvlReq: 3,
    xp: 100,
    permanent: false,
  },
  {
    title: "KING OF THE HILL",
    image: koth,
    description:
      "Reach one of the 4 center squares with your king within move 10 (and win).",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.koth;
    },
    xp: 500,
    lvlReq: 5,
    permanent: false,
  },
  {
    title: "RISKY",
    image: risk,
    description: "5 premoves in a row",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.longest_premove_streak > 4;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "50%",
    image: gaussian,
    description: "Finish a tournament within the top half",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer) {
      let topHalf = false;

      alle_turneringer.forEach((turnering) => {
        const place = turnering.players.indexOf(player.username) + 1;

        if (place === 0) return;

        if (place <= turnering.players.length / 2) {
          topHalf = true;
          return;
        }
      });

      return topHalf;
    },
    xp: 150,
    lvlReq: 3,
    category: "tournament",
    permanent: false,
  },
  {
    title: "DANGER",
    image: danger,
    description: "10 premoves in a row",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.longest_premove_streak > 9;
    },
    xp: 600,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "ANGRY ON THE PORRIDGE",
    image: porridge,
    description: "Give check 3 times within the first 10 moves",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.num_checks_10 > 2;
    },
    lvlReq: 3,
    xp: 300,
    permanent: false,
  },
  {
    title: "KEYSER SÖZE",
    image: soze,
    description: "Capture all of your opponent's pieces",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_brikker_slaatt === 15;
    },
    lvlReq: 5,
    xp: 700,
    permanent: false,
  },
  {
    title: "THE SHORTCUT",
    image: french,
    description: "Play the French",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_e6;
    },
    lvlReq: 2,
    xp: 50,
    permanent: false,
  },
  {
    title: "COMPOSER",
    image: composer,
    description: "Play the Philidor Defence",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_philidor;
    },
    lvlReq: 2,
    xp: 50,
    permanent: false,
  },
  {
    title: "FIGHT CLUB",
    image: fight,
    description: "Capture 8 moves in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_slag_paa_rad > 7;
    },
    lvlReq: 5,
    xp: 800,
    permanent: false,
  },
  {
    title: "QUEEN OF THE HILL",
    image: qoth,
    description:
      "Reach one of the 4 center squares with your queen within move 3 (and win).",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.qoth;
    },
    xp: 250,
    lvlReq: 5,
    permanent: false,
  },
  {
    title: "KNIGHT ON THE RIM",
    image: knight_rim,
    description: "Place a knight on the rim within 3 moves (and win).",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.knight_on_the_rim;
    },
    xp: 300,
    lvlReq: 5,
    permanent: false,
  },
  {
    title: "HANGING SNORE",
    image: snore,
    description: "Get stalemated",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.got_stalemated;
    },
    lvlReq: 3,
    xp: 300,
    permanent: false,
  },
  {
    title: "LONG AND SWEET",
    image: langrokade,
    description: "Queenside castle at move 5",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.long_castle_5;
    },
    lvlReq: 2,
    xp: 70,
    permanent: false,
  },
  {
    title: "BATMAN BEGINS",
    image: batman_begins,
    description: "Play the Alekhine's Defence",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_alekhine;
    },
    lvlReq: 7,
    xp: 80,
    permanent: false,
  },
  {
    title: "THE DARK KNIGHT",
    image: tdk,
    description: "Move a black knight the first 4 moves (and win).",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tdk;
    },
    lvlReq: 7,
    xp: 290,
    permanent: false,
  },
  {
    title: "THE DARK KNIGHT RISES",
    image: tdkr,
    description: "After 1. e4, play 1... Nf6 and 2... Nc6 (and win).",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tdkr;
    },
    lvlReq: 7,
    xp: 300,
    permanent: false,
  },
];
